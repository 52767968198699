<template>
  <v-card id="social-post" style="position: relative" class=" pt-60 pb-15 mb-3 shrink ">

    <!--    <InFeedAdsense
          class="infeed"
          data-ad-client="ca-pub-7691146384467908"
          data-ad-slot="8357716256"
          data-ad-format="auto"
        >
        </InFeedAdsense> -->

<!--    <div class="pa-0 text-center  sticky-top-0 ">
      <v-chip color="primary"
              class="ma-2" @click="changeTimeline(1)"
      >
        Tous
      </v-chip>
      <v-chip class="ma-2" @click="changeTimeline(1, {'category.slug:eq':'4'})"
      >
        Series
      </v-chip>
      <v-chip
        class="" @click="changeTimeline(1, {'category.slug:eq':'5'})"
      >
        Concours/Challenges
      </v-chip>
    </div>-->

    <social-community :user="user"></social-community>

    <post-component class=" social-post"
                    v-for="(post, $index) in postll.listEntity"
                    :key="post.id"
                    :index="$index"
                    :post-item="post"
                    :user="user"
                    :limit="3"
                    @login="()=>dialog.authentication = true"
                    @editPost="(post, index)=>editPost(post, index)"
                    @replyPost="(post, index)=>replyPost(post, index)"
                    @commentPost="commentPost"
                    @postKola="postKola"
                    @deleted="(index)=>deleted(index)"
                    @imageViewer="(item) => {postdetail = item; dialog.imageviewer = true}"
                    @initlogin="()=>{dialog.authentication = true}"
    ></post-component>
    <v-card v-intersect="infiniteScrolling"></v-card>

    <v-card-actions
      v-if="loader.retry"
      class="text-center"
    >
      <v-btn
        class="ma-auto"
        color="primary"
        @click="init(postll.next)"
      >
        Recharger
      </v-btn>
    </v-card-actions>
    <v-sheet
      v-if="loader.loading"
      :color="`grey darken-2 `"
      class="pa-3"
    >
      <v-skeleton-loader
        class="mx-auto"
        max-width="500"
        type="card"
      ></v-skeleton-loader>
    </v-sheet>

    <v-dialog
      v-model="dialog.replypost"
      max-width="600px" persistent
    >
      <post-reply-form-component
        v-model="dialog.replypost"
        :postedit="{
        }"
        :mainpost="postdetail"
        @posted="posted"
        @updated="updated"
        @close="cancelpost"
      ></post-reply-form-component>
    </v-dialog>

    <!--    <v-dialog >
          <post-image-viewer-component
            :post="postdetail"
            @close="()=>{postdetail = {}; dialog.imageviewer = false}"
          ></post-image-viewer-component>
        </v-dialog>-->

    <v-fab-transition>
      <v-btn
        v-show="!dialog.newpost"
        color="primary"
        rounded
        fixed
        large
        dark
        bottom
        right
        class="v-btn--example mb-15"
        @click="newpost"
        style="z-index: 100"
      >
        <v-icon>{{ icons.mdiPlus }}</v-icon>
        Publier
      </v-btn>
    </v-fab-transition>

    <v-dialog fullscreen style="z-index: 1002" v-model="dialog.imageviewer">
      <image-viewer @close="()=>dialog.imageviewer = false" :post="postdetail" :postimages="postdetail.postimages"></image-viewer>
    </v-dialog>

<!--    <v-dialog class="pb-6 overflow-auto"
                    v-model="dialog.newpost"
              fullscreen
    >-->
    <v-expand-x-transition >
      <v-card
        v-show="dialog.newpost"
        height="100"
        width="100"
        class="mx-auto bg-secondary" style="position: fixed; bottom: 0; width: 100%; z-index: 4100" >
        <post-form-component
          v-model="dialog.newpost"
          :postedit="{
      statuskey:'published',
      category : postcategories[0]}"
          :postcategories="postcategories"
          @posted="posted"
          @updated="updated"
          @close="cancelpost"
        >
        </post-form-component>
      </v-card>
    </v-expand-x-transition>
<!--    </v-dialog>-->

    <v-bottom-sheet style="z-index: 4100"
      v-model="dialog.commentpost"
      max-width="600"
      class="pb-12"
    >
      <v-card >
        <v-card-title>
          Commentaires
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialog.commentpost = false"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <comment-component
          :key="postdetail.id"
          :post_id="postdetail.id"
          :quick_comment="false"
          :limit="12"
          :user="user"
          @initlogin="()=>{dialog.authentication = true}"
        ></comment-component>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet style="z-index: 4100"
      v-model="dialog.postkola"
      max-width="600"
      class="pb-12"
    >
      <post-kola-items-component
        @close="()=> dialog.postkola = false"
        :key="postdetail.id"
        :post_id="postdetail.id"
        :user="user"
      ></post-kola-items-component>
    </v-bottom-sheet>

    <v-dialog
      v-model="dialog.authentication"
      max-width="600"
      fullscreen
    >
      <login-component
        :newaccount="false"
        @logged="logged"
        @closedialog="()=> {dialog.authentication = false}"
      ></login-component>
    </v-dialog>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiPlus,
  mdiDotsVertical,
  mdiBookmark,
  mdiShareVariant,
  mdiHeart,
  mdiEyeOutline,
  mdiClose,
  mdiEyeOffOutline,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import { getVuetify } from '@/plugins/utils'
import Banner from '@/components/Banner'
import PostComponent from '@/components/PostComponent'
import PostFormComponent from '@/components/PostFormComponent'
import PostImageViewerComponent from '@/components/PostImageViewerComponent'
import LoginComponent from '@/components/LoginComponent'
import { useRouter } from '@/utils'
import ImageViewer from '@/components/ImageViewer'
import $ from 'jquery'
import PostReplyFormComponent from '@/components/PostReplyFormComponent.vue'
import CommentComponent from '@/components/CommentComponent.vue'
import PostKolaItemsComponent from "@/components/PostKolaItemsComponent.vue";
import SocialCommunity from "@/components/SocialCommunity.vue";

export default {
  components: {
    SocialCommunity,
    PostKolaItemsComponent,
    CommentComponent,
    PostReplyFormComponent,
    ImageViewer,
    LoginComponent,
    PostImageViewerComponent,
    PostFormComponent,
    PostComponent,
  },
  setup() {

    const advertising = ref({})
    const authentication = ref(false)
    const newaccount = ref(false)

    const $vuetify = getVuetify()
    const user = Drequest.getUser()
    const dialog = ref({
      newpost: false,
      replypost: false,
      imageviewer: false,
      authentication: false,
      commentpost: false,
      postkola: false,
    })
    const loader = ref({
      loading: false,
      retry: false,
      options: {},
    })
    const postdetail = ref({
      statuskey:'published', category: {} })
    const postcategories = ref([])
    const postll = ref({})
    const { router } = useRouter()
    const route = router.currentRoute
    const idprofile = route.params.idprofile

    const infiniteScrolling = (entries, observer, isIntersecting) => {
      console.log(loader.value.loading)
      if (loader.value.loading) return

      console.log(postll.value.next >= 2, postll.value)
      if (postll.value.next >= 2) init(postll.value.next)
    }

    const loadData = () => {
/*
      Drequest.api('lazyloading.contest?dfilters=on')
        .param({
          next: 1,
          per_page: 1,
          'active:eq': 1,
        })
        .get(response => {
          console.log(response)
          if (response.listEntity.length) advertising.value = response.listEntity[0]
        })*/

      Drequest.api('lazyloading.tree-item?dfilters=on')
        .param({
          next: 1,
          per_page: 10,
          "status:eq": 1,
          'tree.name:eq': 'post_category',
          'dsort': 'slug asc',
        })
        .get(response => {
          console.log(response)
          if (response.listEntity.length) {
            postcategories.value = response.listEntity
          }
        })

    }
    loadData()

    const init = (next) => {

      if (loader.value.loading) {
        console.log('is loading')
        return
      }
      loader.value.loading = true
      loader.value.retry = false
      Drequest.api('lazyloading.post?dfilters=on&dsort=id desc')
        .param({
          per_page: 3,
          next,
          ...loader.value.options,
          user_id: user.id,
          'status._key:neq': 'draft',
          ...(idprofile ? { 'user.id:eq': idprofile } : {})
        })
        .get(response => {
          // console.log(response)
          loader.value.loading = false
          if (next >= 2) {
            postll.value.listEntity = [...postll.value.listEntity, ...response.listEntity]
            postll.value.next = response.next

          } else {
            postll.value = response
          }

        })
        .fail(e => {
          loader.value.loading = false
          loader.value.retry = true
          console.log(e)
        })
    }
    init(1)

    const changeTimeline = (next, options = {}) => {
      loader.value.options = options
      init(next)
    }

    const detail = cb => {
      drawer.value = true
    }
    const logged = response => {
      console.log(response)
      window.location.reload()
    }
    const posted = post => {
      console.log(post)
      dialog.value.newpost = false
      dialog.value.replypost = false
      postll.value.listEntity.unshift(post)

      // Drequest.__ads.css('display', 'block')

    }
    let postindex = -1
    const updated = post => {
      console.log(post)
      dialog.value.newpost = false
      alert('publication mise a jour')

      // postll.value.listEntity[postindex].content = post.content
      // postll.value.listEntity[postindex].contenthtml = post.contenthtml
    }
    const editPost = (post, index) => {

      console.log(post)
      postdetail.value = post
      postindex = index
      dialog.value.newpost = true

      // Drequest.__ads.css('display', 'none')

    }
    const replyPost = (post, index) => {

      console.log(post)
      postdetail.value = post
      postindex = index
      dialog.value.replypost = true
    }
    const commentPost = (post, index) => {

      dialog.value.newpost = false
      console.log(post)
      postdetail.value = post
      postindex = index
      dialog.value.commentpost = true
    }
    const postKola = (post, index) => {

      console.log(post)
      postdetail.value = post
      postindex = index
      dialog.value.postkola = true
    }
    const deleted = index => {
      console.log(index)
      postll.value.listEntity.splice(index, 1)
    }

    const newpost = () => {
      if (user.id) {
        dialog.value.newpost = true
      } else {
        dialog.value.authentication = true
      }

      // Drequest.__ads.css('display', 'none')

    }
    const cancelpost = () => {
      dialog.value.newpost = false
      dialog.value.replypost = false
      postdetail.value = {}
      // Drequest.__ads.css('display', 'block')
    }

    let lastScrollTop = 0
    let pageheight = 0
    let nextiterator = 1
    window.addEventListener('scroll', () => { // or window.addEventListener("scroll"....
      const stw = window.scrollY // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
      const stb = document.documentElement.scrollTop // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
      const st = window.scrollY || document.documentElement.scrollTop // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
      Drequest.__ads = $('iframe:last-child')
      lastScrollTop = st <= 0 ? 0 : st // For Mobile or negative scrolling

      nextiterator = parseInt(stw / 2700)
      if (nextiterator % 2 === 0) {
        // Drequest.__ads.css('display', 'block')

      } else {

        // Drequest.__ads.css('display', 'none')

      }

    }, false)

    return {
      detail,
      posted,
      updated,
      infiniteScrolling,
      logged,
      changeTimeline,
      newpost,
      cancelpost,
      editPost,
      replyPost,
      commentPost,
      postKola,
      deleted,

      postcategories,
      dialog,
      newaccount,
      postdetail,
      postll,
      loader,
      user,
      advertising,

      icons: {
        mdiPlus,
        mdiDotsVertical,
        mdiBookmark,
        mdiShareVariant,
        mdiHeart,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.sticky-top-0 {

  position: sticky;
  top: 0px ;
  background: white;

}
.overflow-auto{
  overflow: auto;
}
.v-bottom-sheet.v-dialog {
   overflow: auto!important;
 }
.theme--light.v-card > .v-card__text {
  color: rgba(21, 21, 21, 0.98);
}

.v-avatar {
  vertical-align: top !important;
}

.v-list-item__avatar {
  align-self: start !important;
  justify-content: flex-start;
  margin-bottom: 8px;
  margin-top: 8px;
}
</style>
